  // This file is automatically compiled by Webpack, along with any other files
  // present in this directory. You're encouraged to place your actual application logic in
  // a relevant structure within app/javascript and only use these pack files to reference
  // that code so it'll be compiled.

  require("@rails/ujs").start()
  require("turbolinks").start()
  require("@rails/activestorage").start()
  require("channels")
  require('jquery')
  //const flatpickr = require("flatpickr");
  // Uncommenti to copy all static images under ../images to the output folder and reference
  // them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
  // or the `imagePath` JavaScript helper below.
  //
  // const images = require.context('../images', true)
  // const imagePath = (name) => images(name, true)
  import "@fortawesome/fontawesome-free/js/all";
  import 'bootstrap';
  import "../stylesheets/application";
  import "../stylesheets/login";
  import "../stylesheets/global";
  import flatpickr from 'flatpickr';
  import { Spanish } from "flatpickr/dist/l10n/es.js";
  //import 'bootstrap-datepicker';


  // $(document).on('turbolinks:load', function() {
  // alert("NEXT");

  // });

  $(document).ready(function() {
      $("#show_hide_password a").on('click', function(event) {
          event.preventDefault();
          if($('#show_hide_password input').attr("type") == "text"){
              $('#show_hide_password input').attr('type', 'password');
              $('#show_hide_password svg').addClass( "fa-eye-slash" );
              $('#show_hide_password svg').removeClass( "fa-eye" );
          }else if($('#show_hide_password input').attr("type") == "password"){
              $('#show_hide_password input').attr('type', 'text');
              $('#show_hide_password svg').removeClass( "fa-eye-slash" );
              $('#show_hide_password svg').addClass( "fa-eye" );
          }
      });
  });


  $(document).on('turbolinks:load', function() {
      //these methods are called it on load
      components_ready();
      pn_general_profile_ready(); 
      pn_job_profiles_ready();
      pn_financial_profiles_ready();
      crs_forms_ready();
      pn_w9_forms_ready();
      pn_stock_brokerage_accounts_ready();
      pj_general_profiles_ready();
      pj_financial_profiles_ready();
      shareholders_ready();
      pj_related_general_profiles_ready();
      pj_related_financial_profiles_ready();
      pj_crs_status_forms_ready();
      pj_crs_form_control_people_ready();
      pj_stock_brokerage_accounts_ready();
      pj_w8_page2_forms();
      
      $("#pn_general_profile_profesion_id").change(pn_general_profile_ready);
      $("#pn_general_profile_ocupacion_id").change(pn_general_profile_ready);

      $("#pn_general_profile_pregunta_fatca_1").change(pn_general_profile_ready);
      $("#pn_general_profile_pregunta_fatca_2").change(pn_general_profile_ready);
      $("#pn_general_profile_pregunta_fatca_3").change(pn_general_profile_ready);

      $("#pn_general_profile_pep").change(pn_general_profile_ready);
      $("#pn_general_profile_pep_familiar").change(pn_general_profile_ready);
      $("#pn_general_profile_pep_colaborador").change(pn_general_profile_ready);

      $("#pn_job_profile_situacion_laboral_id").change(pn_job_profiles_ready);
      $("#pn_job_profile_cargo_id").change(pn_job_profiles_ready);
      $("#pn_job_profile_actividad_empresa_id").change(pn_job_profiles_ready);

      $("#pn_financial_profile_cambio_patrimonio").change(pn_financial_profiles_ready);

      $("#crs_form_motivo").change(crs_forms_ready);

      $("#pn_stock_brokerage_account_account_purpose_ids_4").change(pn_stock_brokerage_accounts_ready);

      $("#pn_w9_form_federal_tax_classification_id").change(pn_w9_forms_ready);

      $('#crs_form_tiene_tin').change(crs_forms_ready);

      $('#shareholder_parent_company_id').change(shareholder_parent_company);

      $('#pj_crs_status_form_crs_status_id').change(pj_crs_status_forms_ready);

      $('#pj_general_profile_pregunta_fatca').change(pj_general_profiles_ready);

      $('#pj_general_profile_entidad_extranjera_irs').change(pj_general_profiles_ready); 

      $("#pj_financial_profile_cambio_patrimonio").change(pj_financial_profiles_ready); 

      $("#pj_related_general_profile_pep").change(pj_related_general_profiles_ready);

      $("#pj_related_general_profile_pep_familiar").change(pj_related_general_profiles_ready);

      $("#pj_related_general_profile_pep_colaborador").change(pj_related_general_profiles_ready);

      $("#pj_related_general_profile_pregunta_fatca_1").change(pj_related_general_profiles_ready);

      $("#pj_related_general_profile_pregunta_fatca_2").change(pj_related_general_profiles_ready);

      $("#pj_related_general_profile_pregunta_fatca_3").change(pj_related_general_profiles_ready);

      $("#pj_related_financial_profile_cambio_patrimonio").change(pj_related_financial_profiles_ready);

      $("#shareholder_tipo_accionista").change(shareholders_ready);

      $("#pj_stock_brokerage_account_account_purpose_ids_4").change(pj_stock_brokerage_accounts_ready);

      $("#crs_form_control_person_crs_control_type_ids_8").change(pj_crs_form_control_people_ready);

      $("#crs_form_control_person_tiene_tin_1").change(pj_crs_form_control_people_ready);

      $("#crs_form_control_person_tiene_tin_2").change(pj_crs_form_control_people_ready);

      $("#crs_form_control_person_tiene_tin_3").change(pj_crs_form_control_people_ready);

      $("#crs_form_control_person_motivo_1").change(pj_crs_form_control_people_ready);

      $("#crs_form_control_person_motivo_2").change(pj_crs_form_control_people_ready);

      $("#crs_form_control_person_motivo_3").change(pj_crs_form_control_people_ready);

      $("#pj_w8_page2_form_part_iii_b_options_id").change(pj_w8_page2_forms);      

  });

  function components_ready() {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();
    $(".datepicker").flatpickr(
        {
            altInput: true,
            altFormat: "F j, Y",
            "locale": Spanish,
        }
      );
  };


  function pn_general_profile_ready() {
    if ($('#pn_general_profile_profesion_id').val() == '228') {
        $('#otra_profesion').show();
      } else {
        $('#otra_profesion').hide();
        $('#pn_general_profile_otra_profesion').val('')
      }

    if ($('#pn_general_profile_ocupacion_id').val() == '313') {
        $('#otra_ocupacion').show();
      } else {
        $('#otra_ocupacion').hide();
        $('#pn_general_profile_otra_ocupacion').val('')
      }

    if ($('#pn_general_profile_pregunta_fatca_1').val() == 'S' || $('#pn_general_profile_pregunta_fatca_2').val() == 'S' || $('#pn_general_profile_pregunta_fatca_3').val() == 'S') {
        $('#campo_tin').show();
      } else {
        $('#campo_tin').hide();
        $('#pn_general_profile_fatca_tin').val('')
      }

    if ($('#pn_general_profile_pep').val() == 'S' || $('#pn_general_profile_pep_familiar').val() == 'S' || $('#pn_general_profile_pep_colaborador').val() == 'S') {
        $('#explicacion_pep').show();
      } else {
        $('#explicacion_pep').hide();
        $('#pn_general_profile_pep_explicacion').val('')
      }

  }

  function pn_job_profiles_ready() {
    if ($('#pn_job_profile_situacion_laboral_id').val() == '4' || $('#pn_job_profile_situacion_laboral_id').val() == '5') {
        $('.situacion_laboral').show();
      } else {
        $('.situacion_laboral').hide();
        $('#pn_job_profile_nombre_empresa').val('')
        $('#pn_job_profile_cargo_id').val('')
        $('#pn_job_profile_otro_cargo').val('')
        $('#pn_job_profile_actividad_empresa_id').val('')
        $('#pn_job_profile_otra_actividad_empresa').val('')
        $('#pn_job_profile_codigo_telefono_id').val('')
        $('#pn_job_profile_telefono').val('')
        $('#pn_job_profile_direccion').val('')
        $('#pn_job_profile_direccion_postal').val('')
      }

    if ($('#pn_job_profile_cargo_id').val() == '313') {
        $('#otro_cargo').show();
      } else {
        $('#otro_cargo').hide();
        $('#pn_job_profile_otro_cargo').val('')
      }

    if ($('#pn_job_profile_actividad_empresa_id').val() == '62') {
        $('#otra_actividad').show();
      } else {
        $('#otra_actividad').hide();
        $('#pn_job_profile_otra_actividad_empresa').val('')
      }

  }

  function pn_financial_profiles_ready() {
    if ($('#pn_financial_profile_cambio_patrimonio').val() == 'S') {
        $('#motivo_cambio_patrimonio').show();
      } else {
        $('#motivo_cambio_patrimonio').hide();
        $('#pn_financial_profile_motivo_cambio_patrimonio').val('')
      }
  }


  function pj_related_financial_profiles_ready() {
    if ($('#pj_related_financial_profile_cambio_patrimonio').val() == 'S') {
        $('#pj_related_motivo_cambio_patrimonio').show();
      } else {
        $('#pj_related_motivo_cambio_patrimonio').hide();
        $('#pj_related_financial_profile_motivo_cambio_patrimonio').val('')
      }
  } 



  function crs_forms_ready() {

    if ($('#crs_form_tiene_tin').val() == 'S'){
        $('#posee_tin').show();
        $('#no_posee_tin').hide();
        $('#motivo').hide();
        $('#crs_form_explicacion').val('')
        $('#crs_form_motivo').val('') 
        $('#crs_form_identificacion').val('')
    }else if ($('#crs_form_tiene_tin').val() == 'N') {
        $('#posee_tin').hide();
        $('#no_posee_tin').show();
        $('#crs_form_tin').val('')
    }else{
        $('#posee_tin').hide();
        $('#no_posee_tin').hide();
    }


    if ($('#crs_form_motivo').val() == 'Motivo B') {
        $('#motivo').show();
      } else {
        $('#motivo').hide();
        $('#crs_form_explicacion').val('')
      }

  }

  function pn_stock_brokerage_accounts_ready() {
    if ($('#pn_stock_brokerage_account_account_purpose_ids_4').is(':checked')) {
        $('#otros_motivo').show();
      } else {
        $('#otros_motivo').hide();
        $('#pn_stock_brokerage_account_otro_proposito_cuenta').val('');
      }
  }

  function pn_w9_forms_ready() {
    if ($('#pn_w9_form_federal_tax_classification_id').val() == '6') {
        $('#tax_classification').show();
      } else {
        $('#tax_classification').hide();
        $('#pn_w9_form_tax_classification').val('')
      }

    if ($('#pn_w9_form_federal_tax_classification_id').val() == '7') {
        $('#other_explanation').show();
      } else {
        $('#other_explanation').hide();
        $('#pn_w9_form_other').val('')
      }
  }


  function pj_general_profiles_ready(){
    if ($('#pj_general_profile_pregunta_fatca').val() == 'S') {
      $('#campo_tin_pj').show();
    }else{
      $('#campo_tin_pj').hide();
      $('#pj_general_profile_tin').val('')
    }

    if ($('#pj_general_profile_entidad_extranjera_irs').val() == 'S') {
      $('#campo_giin_pj').show();
    }else{
      $('#campo_giin_pj').hide();
      $('#pj_general_profile_giin').val('')
    }
  }

  function pj_financial_profiles_ready() {
    if ($('#pj_financial_profile_cambio_patrimonio').val() == 'S') {
        $('#pj_motivo_cambio_patrimonio').show();
      } else {
        $('#pj_motivo_cambio_patrimonio').hide();
        $('#pj_financial_profile_motivo_cambio_patrimonio').val('')
      }
  } 

  function shareholders_ready() {
    if ($('#shareholder_tipo_accionista').val() == 'N') {
        $('.accionista_pn').show();
        $('.accionista_pj').hide();
    }else if ($('#shareholder_tipo_accionista').val() == 'J') {
        $('.accionista_pj').show();
        $('.accionista_pn').hide();
    } else {
        $('.accionista_pj').hide();
        $('.accionista_pn').hide();
    }
  }

  function shareholder_parent_company() {
    if ($('#shareholder_parent_company_id option:selected').attr('parent_company_type') == 'Shareholder') {
      $('#shareholder_parent_company_type').val('Shareholder')
    } else if ($('#shareholder_parent_company_id option:selected').attr('parent_company_type') == 'JuridicalPerson'){
      $('#shareholder_parent_company_type').val('JuridicalPerson')
    }
  }
 

  function pj_crs_status_forms_ready(){
    if ($('#pj_crs_status_form_crs_status_id').val() == '5') {
      $('#mercado_valores_entidad').show();
    }else{
      $('#mercado_valores_entidad').hide();
      $('#mercado_valores_entidad').val('')
    }

  }

  function pj_stock_brokerage_accounts_ready() {
    if ($('#pj_stock_brokerage_account_account_purpose_ids_4').is(':checked')) {
        $('#pj_otros_motivo').show();
      } else {
        $('#pj_otros_motivo').hide();
        $('#pj_stock_brokerage_account_otro_proposito_cuenta').val('');
      }
  } 

  function pj_related_general_profiles_ready() {
    if ($('#pj_related_general_profile_pep').val() == 'S' || $('#pj_related_general_profile_pep_familiar').val() == 'S' || $('#pj_related_general_profile_pep_colaborador').val() == 'S') {
        $('#pj_explicacion_pep').show();
      } else {
        $('#pj_explicacion_pep').hide();
        $('#pj_related_general_profile_pep_explicacion').val('')
      }

    if ($('#pj_related_general_profile_pregunta_fatca_1').val() == 'S' || $('#pj_related_general_profile_pregunta_fatca_2').val() == 'S' || $('#pj_related_general_profile_pregunta_fatca_3').val() == 'S') {
        $('#pj_campo_tin').show();
      } else {
        $('#pj_campo_tin').hide();
        $('#pj_related_general_profile_fatca_tin').val('')
      }
  }

  function pj_crs_form_control_people_ready() {
    if ($('#crs_form_control_person_crs_control_type_ids_8').is(':checked')) {
        $('#otro_control').show();
      } else {
        $('#otro_control').hide();
        $('#crs_form_control_person_otro_tipo_control').val('');
      }

    if ($('#crs_form_control_person_tiene_tin_1').val() == 'S') {
        $('#posee_tin_1').show();
      } else {
        $('#posee_tin_1').hide();
        $('#crs_form_control_person_tin_1').val('')
      }

    if ($('#crs_form_control_person_tiene_tin_2').val() == 'S') {
        $('#posee_tin_2').show();
      } else {
        $('#posee_tin_2').hide();
        $('#crs_form_control_person_tin_2').val('')
      }

    if ($('#crs_form_control_person_tiene_tin_3').val() == 'S') {
        $('#posee_tin_3').show();
      } else {
        $('#posee_tin_3').hide();
        $('#crs_form_control_person_tin_3').val('')
      }

    if ($('#crs_form_control_person_motivo_1').val() == 'Motivo B') {
        $('#motivo_1').show();
      } else {
        $('#motivo_1').hide();
        $('#crs_form_control_person_explicacion_1').val('')
      }

    if ($('#crs_form_control_person_motivo_2').val() == 'Motivo B') {
        $('#motivo_2').show();
      } else {
        $('#motivo_2').hide();
        $('#crs_form_control_person_explicacion_2').val('')
      }

    if ($('#crs_form_control_person_motivo_3').val() == 'Motivo B') {
        $('#motivo_3').show();
      } else {
        $('#motivo_3').hide();
        $('#crs_form_control_person_explicacion_3').val('')
      }

    if ($('#crs_form_control_person_tiene_tin_1').val() == 'S'){
        $('#posee_tin_1').show();
        $('#no_posee_tin_1').hide();
        $('#motivo_1').hide();
        $('#crs_form_control_person_explicacion_1').val('')
        $('#crs_form_control_person_motivo_1').val('') 
        $('#crs_form_control_person_identificacion_1').val('')
    }else if ($('#crs_form_control_person_tiene_tin_1').val() == 'N') {
        $('#posee_tin_1').hide();
        $('#no_posee_tin_1').show();
        $('#crs_form_control_person_tin_1').val('')
    }else{
        $('#posee_tin_1').hide();
        $('#no_posee_tin_1').hide();
    }


    if ($('#crs_form_control_person_motivo_2').val() == 'Motivo B') {
        $('#motivo_2').show();
      } else {
        $('#motivo_2').hide();
        $('#crs_form_control_person_explicacion_2').val('')
      }

    if ($('#crs_form_control_person_tiene_tin_2').val() == 'S'){
        $('#posee_tin_2').show();
        $('#no_posee_tin_2').hide();
        $('#motivo_2').hide();
        $('#crs_form_control_person_explicacion_2').val('')
        $('#crs_form_control_person_motivo_2').val('') 
        $('#crs_form_control_person_identificacion_2').val('')
    }else if ($('#crs_form_control_person_tiene_tin_2').val() == 'N') {
        $('#posee_tin_2').hide();
        $('#no_posee_tin_2').show();
        $('#crs_form_control_person_tin_2').val('')
    }else{
        $('#posee_tin_2').hide();
        $('#no_posee_tin_2').hide();
    }


    if ($('#crs_form_control_person_motivo_2').val() == 'Motivo B') {
        $('#motivo_2').show();
      } else {
        $('#motivo_2').hide();
        $('#crs_form_control_person_explicacion_2').val('')
      }

    if ($('#crs_form_control_person_tiene_tin_3').val() == 'S'){
        $('#posee_tin_3').show();
        $('#no_posee_tin_3').hide();
        $('#motivo_3').hide();
        $('#crs_form_control_person_explicacion_3').val('')
        $('#crs_form_control_person_motivo_3').val('') 
        $('#crs_form_control_person_identificacion_3').val('')
    }else if ($('#crs_form_control_person_tiene_tin_3').val() == 'N') {
        $('#posee_tin_3').hide();
        $('#no_posee_tin_3').show();
        $('#crs_form_control_person_tin_3').val('')
    }else{
        $('#posee_tin_3').hide();
        $('#no_posee_tin_3').hide();
    }


    if ($('#crs_form_control_person_motivo_3').val() == 'Motivo B') {
        $('#motivo_3').show();
      } else {
        $('#motivo_3').hide();
        $('#crs_form_control_person_explicacion_3').val('')
      }

  } 

  function pj_w8_page2_forms() {
    if ($('#pj_w8_page2_form_part_iii_b_options_id').val() == '10') {
        $('#other_explanation').show();
      } else {
        $('#other_explanation').hide();
        $('#pj_w8_page2_form_part_iii_b_options_other').val('')
      }
  }


  $(document).on('click', '.add_other_element', function() {
      var element = document.getElementsByClassName("pais_2")[0]
      if (element.classList.contains("hidden") && (element.style.display != "block"))  {
        element.style.display = "block";
      } else {
        document.getElementsByClassName("pais_3")[0].style.display = "block"
        document.getElementsByClassName("add_other_element")[0].style.display = "none"
      }
    });



  addEventListener("direct-upload:initialize", event => {
    const { target, detail } = event
    const { id, file } = detail
    target.insertAdjacentHTML("beforebegin", `
      <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
        <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
        <span class="direct-upload__filename">${file.name}</span>
      </div>
    `)
  })

  addEventListener("direct-upload:start", event => {
    const { id } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.remove("direct-upload--pending")
  })

  addEventListener("direct-upload:progress", event => {
    const { id, progress } = event.detail
    const progressElement = document.getElementById(`direct-upload-progress-${id}`)
    progressElement.style.width = `${progress}%`
  })

  addEventListener("direct-upload:error", event => {
    event.preventDefault()
    const { id, error } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.add("direct-upload--error")
    element.setAttribute("title", error)
  })

  addEventListener("direct-upload:end", event => {
    const { id } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.add("direct-upload--complete")
  })






